import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";

export interface WalletModel {
  walletTransactionList: Array<walletInfo>;
  walletTransactionDetail: WalletTransactionDetail;
  walletTransactionInvoice: Array<TransactionInvoice>;
}

export interface walletInfo {
  transaction_partner_id: number;
  transaction_partner_order_code: string;
  transaction_partner_type: string;
  transaction_partner_description: string;
  transaction_partner_we_credit: number;
  transaction_partner_province_id: number;
  create_at: string;
}
// bass
export interface walletInfoInvoid {
  transaction_partner_id: number;
  transaction_partner_order_code: string;
  transaction_partner_type: string;
  transaction_partner_description: string;
  transaction_partner_we_credit: number;
  transaction_partner_province_id: number;
  create_at: string;
}
export interface selection {
  name: string;
  value: number;
}

interface WalletTransactionDetail {
  transaction_order: TransactionOrder;
  rider_information: RiderInformation;
  order: Order;
}

interface TransactionOrder {
  transaction_partner_id: number;
  transaction_partner_order_code: string;
  transaction_partner_type: string;
  transaction_partner_description: string;
  transaction_partner_we_credit: number;
  transaction_partner_province_id: number;
  transaction_partner_profit_and_loss: TransactionPartnerProfitAndLoss;
  create_at: string;
}
interface BillDetail {
  name: string;
  unit: number;
  price: number;
}
interface BillType {
  bill_type_id: number;
  bill_type_name: string;
}
interface TransactionInvoice {
  msg: boolean;
  data: {
    bill_id: number;
    bill_type: BillType;
    bill_provider: string;
    bill_number: string;
    bill_description: string;
    bill_issuer_name: string;
    bill_issuer_address: string;
    bill_issuer_tax_id: string;
    bill_customer_name: string;
    bill_customer_address: string;
    bill_customer_tax_id: string;
    bill_detail: BillDetail[];
    bill_user_partner_id: number;
    bill_district_id: number;
    bill_amount_price: number;
    bill_discount_price: number;
    bill_deposit_price: number;
    bill_vat_price: number;
    bill_net_price: number;
    bill_net_price_text: string;
    bill_due_date: string;
    bill_status: number;
  };
}

interface TransactionPartnerProfitAndLoss {
  data_profit_and_loss_order: data_profit_and_loss_order;
  data_profit_and_loss_rider: data_profit_and_loss_rider;
  summary_profit_and_loss: summary_profit_and_loss;
}
interface data_profit_and_loss_order {
  GP: Gp;
  extra_transport: ExtraTransport;
  small_order: small_order;
  promotion: Promotion;
  summary_order: SummaryOrder;
}
interface data_profit_and_loss_rider {
  fee_delivery_differentail: FeeDeliveryDifferent;
  fee_delivery: FreeDelivery;
  guarantee_fee: GuaranteeFee;
  summary_rider: SummaryRider;
}
interface summary_profit_and_loss {
  weserve: number;
  manager: number;
}
interface SummaryOrder {
  weserve: number;
  manager: number;
}
interface small_order {
  total: number;
  weserve: number;
  manager: number;
}
interface FreeDelivery {
  total: number;
  weserve: number;
  manager: number;
}
interface SummaryRider {
  weserve: number;
  manager: number;
}
interface GuaranteeFee {
  total: number;
  weserve: number;
  manager: number;
}
interface FeeDeliveryDifferent {
  total: number;
  weserve: number;
  manager: number;
}
interface Gp {
  total: number;
  weserve: number;
  manager: number;
}

interface FreeDelivery {
  total: number;
  weserve: number;
  manager: number;
}

interface ExtraTransport {
  total: number;
  weserve: number;
  manager: number;
}

interface Promotion {
  total: number;
  weserve: number;
  manager: number;
}

interface RiderInformation {
  rider_name: string;
  rider_tel: string;
  rider_area: RiderArea[];
  rider_vehicle_type: string;
}

interface RiderArea {
  district_id: number;
  district_name_th: string;
  district_name_en: string;
}

interface Order {
  order_id: number;
  order_type: number;
  order_code: string;
  order_customer_id: number;
  order_customer_full_name: string;
  order_rider_id: number;
  order_rider_full_name: string;
  order_store: OrderStore;
  order_customer_address_id: number;
  order_customer_address_zone: string;
  order_amount_point: number;
  order_discount: string;
  order_discount_code: string;
  order_total_addon_price: string;
  order_total_price: string;
  order_net_price: string;
  order_transfer_price: string;
  order_transfer_extra_time_price: string;
  order_store_extra_price: string;
  order_vat_price: string;
  order_service_charge_price: string;
  order_total_discount_price: string;
  order_platform: string;
  order_payment_type: number;
  order_is_paymented: number;
  order_payment_time: string;
  order_note: string;
  order_create_by_id: string;
  order_update_by_id: string;
  order_status: number;
  order_send_data: number;
  order_time_start: string;
  order_time_end: string;
  create_at: string;
  update_at: string;
  order_distance: number;
  order_customer: OrderCustomer;
  order_customer_address: OrderCustomerAddress;
  order_points: OrderPoint[];
}

interface OrderStore {
  store_name_th: string;
  store_name_en: string;
  store_tel: StoreTel[];
  store_address_th: string;
  store_address_en: string;
  store_note: string;
  store_lat: string;
  store_long: string;
}

interface StoreTel {
  tel: string;
}

interface OrderCustomer {
  user_id: number;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_country_tel: string;
  user_tel: string;
}

interface OrderCustomerAddress {
  user_address_text: string;
  user_address_contact_tel: string;
  user_address_lat: string;
  user_address_long: string;
  user_address_note: string;
}

interface OrderPoint {
  order_point_id: number;
  order_point_lat: string;
  order_point_long: string;
  order_point_address: string;
  order_point_submit_photo: string;
  order_point_status: number;
  order_point_to: string;
  order_point_list_tel: string;
  order_point_remark: string;
  order_point_number: number;
  order_point_zone: string;
  order_point_zone_id: number;
}

@Module({ namespaced: true })
export default class WalletModule extends VuexModule implements WalletModel {
  walletTransactionInvoice = [] as Array<TransactionInvoice>;
  walletTransactionDetail = {
    transaction_order: {
      transaction_partner_profit_and_loss: {
        data_profit_and_loss_order: {
          GP: {},
          extra_transport: {},
          small_order: {},
          promotion: {},
          summary_order: {},
        },
        data_profit_and_loss_rider: {
          fee_delivery_differentail: {},
          fee_delivery: {},
          guarantee_fee: {},
          summary_rider: {},
        },
        summary_profit_and_loss: {},
      },
    },
    rider_information: {
      rider_area: [] as RiderArea[],
    },
    order: {
      order_store: {},
      order_customer: {},
      order_customer_address: {},
    },
  } as WalletTransactionDetail;

  walletTransactionList = [] as Array<walletInfo>;
  filterTransactionForm = {
    search_text: "",
  };

  get getTransactionList(): Array<walletInfo> {
    return this.walletTransactionList.map((val, index) => {
      return { no: index + 1, ...val };
    });
  }

  @Mutation
  [Mutations.SET_WALLET_TRANSACTION_LIST](data: walletInfo[]) {
    this.walletTransactionList = data;
  }

  @Mutation
  [Mutations.SET_WALLET_TRANSACTION_BY_ID](data: WalletTransactionDetail) {
    this.walletTransactionDetail = data;
    //console.log(this.walletTransactionDetail);
  }
  @Mutation
  [Mutations.SET_WALLET_TRANSACTION_BY_INVOICE](data: TransactionInvoice[]) {
    this.walletTransactionInvoice = data;
    // console.log(this.walletTransactionInvoice);
  }
  @Action
  [Actions.FETCH_WALLET_TRANSACTION_LIST]() {
    return (
      ApiService.query(`/api/v1/partner/get_transaction_manager`, {})
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier

          this.context.commit(Mutations.SET_WALLET_TRANSACTION_LIST, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }

  @Action
  [Actions.FETCH_WALLET_TRANSACTION_BY_ID]({ transactionId, orderCode }) {
    return (
      ApiService.get(
        `/api/v1/partner/get_transaction_detail_manager/${transactionId}`,
        orderCode
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          this.context.commit(
            Mutations.SET_WALLET_TRANSACTION_BY_ID,
            data.data
          );
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.FETCH_WALLET_TRANSACTION_BY_INVOICE]({ transactionIdinvoice }) {
    return (
      ApiService.get(`api/v1/partner/get_invoice_detail`, transactionIdinvoice)
        .then(({ data }) => {
          if (data.msg == false) return false;
          this.context.commit(
            Mutations.SET_WALLET_TRANSACTION_BY_INVOICE,
            data.data
          );
          //console.log(data.data);
          // eslint-disable-next-line prettier/prettier
          console.log(data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.TOPUP_CREDIT_WALLET](amount) {
    const payload = {
      transaction_partner_we_credit: amount,
      transaction_partner_type: "Top Up",
      gb_pay_provider: "manager",
      gb_pay_note: "note",
    };
    return (
      ApiService.post(
        `/api/v1/partner/create_gb_pay`,
        payload as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }

  @Action
  [Actions.CHECK_STATUS_TOPUP](referenceNo) {
    const payload = {
      referenceNo: referenceNo,
    };
    return (
      ApiService.post(
        `/api/v1/partner/check_status_gb_pay`,
        payload as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
}
