import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";

export interface Bank {
  get_bank: Array<bankdata>;
  get_detail_bank: getBankDetail;
  get_create_bank: get_create_bank;
  get_detail_default: get_create_bank;
  get_bank_name: Array<bankname>;
  get_approve_default: Array<bankapprove>;
  get_bank_reject: Array<getBankDetail>;
}
interface get_create_bank {
  bank_manager_id: number;
  bank_manager_bank: detailBank;
  bank_manager_customer_name: string;
  bank_manager_customer_id_card: string;
  bank_manager_status: number;
  bank_manager_default_bank: number;
}
interface bankapprove {
  bank_manager_id: number;
  bank_manager_bank: detailBank;
  bank_manager_bank_number: string;
  bank_manager_customer_name: string;
  bank_manager_customer_id_card: string;
  bank_manager_image_customer_id_card: string;
  bank_manager_image_book_bank: string;
  bank_manager_status: number;
  bank_manager_remark: string;
}
interface bankname {
  bank_id: number;
  bank_code: string;
  bank_name_th: string;
  bank_name_en: string;
  bank_icon: string;
}
interface getBankDetail {
  bank_manager_id: number;
  bank_manager_bank: detailBank;
  bank_manager_bank_number: string;
  bank_manager_customer_name: string;
  bank_manager_customer_id_card: string;
  bank_manager_image_customer_id_card: string;
  bank_manager_image_book_bank: string;
  bank_manager_status: number;
  bank_manager_remark: string;
}
export interface getBank {
  data: bankdata[];
}
interface bankdata {
  bank_manager_id: number;
  bank_manager_bank: detailBank;
  bank_manager_bank_number: string;
  bank_manager_customer_name: string;
  bank_manager_customer_id_card: string;
  bank_manager_status: number;
  bank_manager_default_bank: number;
}
interface detailBank {
  bank_id: number;
  bank_code: string;
  bank_name_th: string;
  bank_name_en: string;
  bank_icon: string;
}

@Module({ namespaced: true })
export default class BankModule extends VuexModule implements Bank {
  get_bank = [] as Array<bankdata>;
  get_bank_name = [] as Array<bankname>;
  get_detail_bank = {
    bank_manager_bank: {},
  } as getBankDetail;
  get_bank_reject = [] as Array<getBankDetail>;
  get_approve_default = [] as Array<bankapprove>;
  get_create_bank = {} as get_create_bank;
  get_detail_default = {} as get_create_bank;
  filterBankListForm = {
    bank_text: "",
    bank_text_default: "",
  };
  get_bank_delete = {} as getBankDetail;
  get bankList(): Array<bankdata> {
    return this.get_bank.map((val, index) => {
      return { no: index + 1, ...val };
    });
  }
  get bankListDefault(): Array<bankapprove> {
    return this.get_approve_default.map((val, index) => {
      return { no: index + 1, ...val };
    });
  }
  @Mutation
  [Mutations.SET_BANK_DETAIL](data: getBankDetail) {
    this.get_detail_bank = data;
  }
  @Mutation
  [Mutations.SET_BANK_DELETE](data: getBankDetail) {
    this.get_bank_delete = data;
    const index = this.get_bank.findIndex(
      (bank) => bank.bank_manager_id === data.bank_manager_id
    );
    this.get_bank.splice(index, 1);
  }
  @Mutation
  [Mutations.SET_BANK_DEFAULT](data: get_create_bank) {
    this.get_detail_default = data;
  }
  @Mutation
  [Mutations.SET_BANK_CREATE](data: get_create_bank) {
    this.get_create_bank = data;
  }
  @Mutation
  [Mutations.GET_BANK](data: bankdata[]) {
    this.get_bank = data;
  }
  @Mutation
  [Mutations.GET_BANK_REJECT](data: bankdata[]) {
    this.get_bank = data;
  }
  @Mutation
  [Mutations.GET_BANK_DEFAULT](data: bankapprove[]) {
    this.get_approve_default = data.filter(
      (word) => word.bank_manager_status === 2
    );
  }
  @Mutation
  [Mutations.GET_BANK_NAME](data: bankname[]) {
    this.get_bank_name = data;
  }
  @Action
  [Actions.FETCH_LIST_BANK]() {
    return (
      ApiService.query(`/api/v1/partner/get_list_bank_manager`, {})
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.GET_BANK, data.data);
          this.context.commit(Mutations.GET_BANK_DEFAULT, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }
  [Actions.GET_IMG_BANK](img) {
    return console.log(img);
  }
  @Action
  [Actions.FETCH_LIST_BANK_DETAIL](id) {
    return (
      ApiService.get(`/api/v1/partner/get_bank_manager_detail`, id)
        .then(({ data }) => {
          if (data.msg == false) return false;
          this.context.commit(Mutations.SET_BANK_DETAIL, data.data);
          // eslint-disable-next-line prettier/prettier
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.FETCH_LIST_BANK_NAME]() {
    return (
      ApiService.query(`/api/v1/partner/get_thai_bank`, {})
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.GET_BANK_NAME, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }
  @Action
  [Actions.TOPUP_CREATE_BANK]({ formInput }) {
    const payload = {
      bank_manager_customer_name: formInput.nameName,
      bank_manager_bank_number: formInput.bankNumber,
      bank_manager_bank_id: formInput.bankName,
      bank_manager_customer_id_card: formInput.idCard,
      bank_manager_image_customer_id_card: formInput.documentId,
      bank_manager_image_book_bank: formInput.documentBank,
    };
    return (
      ApiService.post(
        `/api/v1/partner/create_bank_manager`,
        payload as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          this.context.commit(Mutations.SET_BANK_CREATE, data.data);
          // eslint-disable-next-line prettier/prettier
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.TOPUP_DEFAULT_BANK](defaultBank) {
    const defaultBankTest = {
      bank_manager_id: defaultBank,
    };
    return (
      ApiService.put(
        `/api/v1/partner/set_default_bank_manager`,
        defaultBankTest as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_BANK_DEFAULT, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.DELETE_BANK](toString) {
    return (
      ApiService.delete(`/api/v1/partner/delete_bank_manager`, toString)
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_BANK_DELETE, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
}
