enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  REFRESH_TOKEN = "refresh_token",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_ORDER_REPORT = "getOrderReport",
  GET_PROMOTION_REPORT = "getPromotionReport",
  GET_RIDER_REPORT = "getRiderReport",
  FETCH_RIDER_LIST = "fetchRiderList",
  FETCH_RIDER_BY_ID = "fetchRiderById",
  FETCH_USER_INFO = "fetchUserInfo",
  FETCH_WALLET_TRANSACTION_LIST = "fetchWalletTransactionList",
  FETCH_WALLET_TRANSACTION_BY_ID = "fetchWalletTransactionByID",
  TOPUP_CREDIT_WALLET = "topupCreditWallet",
  CHECK_STATUS_TOPUP = "checkStatusTopup",
  FETCH_BILL_LIST = "fetchbillist",
  FETCH_BILL_BY_ID = "fetchbillbyid",
  FETCH_COUNT_BILL = "fetchcountbill",
  TOPUP_CREDIT_BILL = "topupcreditbill",
  TOPUP_CREDIT_PAY = "topupcreditpay",
  FETCH_WALLET_TRANSACTION_BY_INVOICE = "fetchwwalletTransactionByinvoice",
  FETCH_LIST_BANK = "fetchlistBank",
  TOPUP_CREATE_BANK = "topupCreateBank",
  TOPUP_DEFAULT_BANK = "topupDefaultBank",
  FETCH_LIST_BANK_DETAIL = "fetchListBankDetail",
  DELETE_BANK = "deletebank",
  GET_IMG_BANK = "getimgbank",
  FETCH_LIST_BANK_NAME = "fetchListBankName",
  FETCH_BANK_APPROVE = "fetchbankapprove",
  CAL_WITHDRAW_WALLET = "calwhdrawwallet",
  CREATE_WITHDRAW = "createwithdraw",
  FETCH_BANK_DETAIL = "fetchbankdetail",
  GET_ORDER_REPORT_MESSENGER = "getOrderReportMessenger",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_ORDER_REPORT = "setOrderReport",
  SET_PROMOTION_REPORT = "setPromotionReport",
  SET_ORIGINAL_ORDER_CONFIG = "setOriginalOrderConfig",
  SET_ORIGINAL_PROMOTION_CONFIG = "setOriginalPromotionConfig",
  SET_REFRESH = "setRefresh",
  SET_RIDER_REPORT = "setRiderReport",
  SET_ORIGINAL_RIDER_CONFIG = "setOriginalRiderConfig",
  SET_RIDER_LIST = "setRiderList",
  SET_RIDER_BY_ID = "setRiderById",
  SET_USER_CREDIT_WALLET = "setUserCoinWallet",
  SET_INTERVAL_CREDIT_WALLET = "setIntervalCreditWallet",
  CLEAR_INTERVAL_CREDIT_WALLET = "clearIntervalCreditWallet",
  SET_WALLET_TRANSACTION_LIST = "setWalletTransactionList",
  SET_WALLET_TRANSACTION_BY_ID = "setWalletTransactionByID",
  SET_BILL_LIST = "setbilllist",
  SET_COUNT_BILL = "setcountbill",
  SET_BILL_ID_LIST = "setbillidlist",
  SET_BILL_PAY_LIST = "setbillpaylist",
  SET_WALLET_TRANSACTION_BY_INVOICE = "setwallettransactionbyinvoice",
  BILL_PAY = "billpay",
  BILL_PAY_CHECK = "billpaycheck",
  GET_BANK = "getbank",
  SET_BANK_DETAIL = "setbankdetail",
  SET_BANK_CREATE = "setbankcreate",
  SET_BANK_DEFAULT = "setbankdefault",
  SET_BANK_DELETE = "setbankdelete",
  GET_BANK_NAME = "getbankname",
  GET_BANK_DEFAULT = "getbankdefault",
  GET_BANK_REJECT = "getbankreject",
  SET_WALLET_BANK_LIST = "setwalletbanklist",
  SET_WITHDRAW = "setwithdraw",
  SET_WITHDRAW_DETAIL = "setwithdrawdetail",
}

export { Actions, Mutations };
